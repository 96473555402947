<template>
<div class="agendar-horario">
  <div class="parte-superior d-flex flex-row w-100 container">

    <div class="d-flex flex-column w-50">
      <full-calendar :options="calendarOptions" class="w-100 container teste-css"></full-calendar>
      <div class="d-flex px-2">
        <div class="info-mark-text container ms-1 my-3 bd-all">
          <p>Prezado(a) professor(a)</p>
          <p>Informamos que o horário da Equipe Web é:</p>
          <ul>
            <li>Segunda à Sexta: 08:00 às 21:00</li>
            <li>Sábado 08:00 às 17:00</li>
          </ul>
          <p>Lembramos que qualquer webconferência realizada fora do horário comercial não poderá contar com o suporte técnico da Equipe Web(Ex: Domingos e feriados)</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div  class="col-12">
        <ul class="nav nav-tabs" id="myTab">
          <li class="nav-item" id="liTabCabine" v-if="!nameCurso == 0">
            <button class="nav-link" id="buttonTabCabine" data-bs-target="#tab-presencial" data-bs-toggle="tab">Cabines</button>
          </li>
          <li class="nav-item" v-if="!nameCurso == 0">
            <button class="nav-link" id="buttonTabHome" data-bs-target="#tab-home-office" data-bs-toggle="tab">Home-Office</button>
          </li>
          <li class="nav-item" id="liTabPos" v-if="nameCurso == 0">
            <button class="nav-link" id="buttonTabPos" data-bs-target="#tab-pos-graduacao" data-bs-toggle="tab">Graduação ao vivo</button>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab-presencial" class="tab-pane fade" aria-labelledby="home-tab" role="tabpanel">
            <div id="modal-table-meet w-50 container">
              <div class="info-mark-meet d-flex flex-column">
                <div class="mb-2">
                  <h5>Disponibilidade das salas:</h5>
                  <span>Salas/Horários do dia  {{this.showDateSelected.day}}/{{this.showDateSelected.month}}/{{this.showDateSelected.year}}</span>
                </div>
                <div>
                  <h5 class="d-flex flex-row mb-3">Legendas:</h5>
                  <div class="d-flex flex-row justify-content-center mb-3">
                    <div class="info-data">
                      <span class="info-title-deactive bd-all p-1">D</span>
                      <span class="bd-all p-1">Desativada por uso.</span>
                    </div>
                    <div class="info-data">
                      <span class="info-title-reserved bd-all p-1">X</span>
                      <span class="bd-all p-1">Já reservada.</span></div> 
                  </div>
                  <div class="d-flex flex-row justify-content-center mb-3">
                    <div class="info-data">
                      <span class="info-title-me-reserved bd-all p-1">X</span>
                      <span class="bd-all p-1">Ocupada por você, mas não reservada.</span></div>

                      <div class="info-data">
                      <span class="info-title-me bd-all p-1">R</span>
                      <span class="bd-all p-1">Reservado por você.</span></div>
                  </div>
                </div>
              </div>
              <table class="container">
                <tbody>
                  <tr class="table-titles">
                    <th class="th-first">Hora/Sala</th>
                    <th id="1">1</th>
                    <th id="2">2</th>
                    <th id="3">3</th>
                    <th id="4">4</th>
                    <th id="5">5</th>
                    <th id="6">6</th>
                    <th id="7">7</th>
                    <th id="8">8</th>
                    <th id="9">9</th>
                    <th id="10">10</th>
                    <th id="11">11</th>
                    <th id="12">12</th>
                    <th id="13">13</th>
                    <th id="14" class="th-last">14</th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th>08:30 - 09:30</th>
                    <td>
                      <input class="form-check-input class1 08:30:00" type="radio" id="08:30:00-1" value="08:30:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 08:30:00" type="radio" id="08:30:00-2" value="08:30:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 08:30:00" type="radio" id="08:30:00-3" value="08:30:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 08:30:00" type="radio" id="08:30:00-4" value="08:30:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 08:30:00" type="radio" id="08:30:00-5" value="08:30:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 08:30:00" type="radio" id="08:30:00-6" value="08:30:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 08:30:00" type="radio" id="08:30:00-7" value="08:30:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 08:30:00" type="radio" id="08:30:00-8" value="08:30:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 08:30:00" type="radio" id="08:30:00-9" value="08:30:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 08:30:00" type="radio" id="08:30:00-10" value="08:30:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 08:30:00" type="radio" id="08:30:00-11" value="08:30:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 08:30:00" type="radio" id="08:30:00-12" value="08:30:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 08:30:00" type="radio" id="08:30:00-13" value="08:30:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 08:30:00" type="radio" id="08:30:00-14" value="08:30:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>10:00 - 11:00</th>
                    <td>
                      <input class="form-check-input class1 10:00:00" type="radio" id="10:00:00-1" value="10:00:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 10:00:00" type="radio" id="10:00:00-2" value="10:00:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 10:00:00" type="radio" id="10:00:00-3" value="10:00:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 10:00:00" type="radio" id="10:00:00-4" value="10:00:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 10:00:00" type="radio" id="10:00:00-5" value="10:00:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 10:00:00" type="radio" id="10:00:00-6" value="10:00:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 10:00:00" type="radio" id="10:00:00-7" value="10:00:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 10:00:00" type="radio" id="10:00:00-8" value="10:00:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 10:00:00" type="radio" id="10:00:00-9" value="10:00:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 10:00:00" type="radio" id="10:00:00-10" value="10:00:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 10:00:00" type="radio" id="10:00:00-11" value="10:00:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 10:00:00" type="radio" id="10:00:00-12" value="10:00:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 10:00:00" type="radio" id="10:00:00-13" value="10:00:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 10:00:00" type="radio" id="10:00:00-14" value="10:00:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>11:30 - 12:30</th>
                    <td>
                      <input class="form-check-input class1 11:30:00" type="radio" id="11:30:00-1" value="11:30:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 11:30:00" type="radio" id="11:30:00-2" value="11:30:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 11:30:00" type="radio" id="11:30:00-3" value="11:30:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 11:30:00" type="radio" id="11:30:00-4" value="11:30:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 11:30:00" type="radio" id="11:30:00-5" value="11:30:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 11:30:00" type="radio" id="11:30:00-6" value="11:30:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 11:30:00" type="radio" id="11:30:00-7" value="11:30:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 11:30:00" type="radio" id="11:30:00-8" value="11:30:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 11:30:00" type="radio" id="11:30:00-9" value="11:30:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 11:30:00" type="radio" id="11:30:00-10" value="11:30:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 11:30:00" type="radio" id="11:30:00-11" value="11:30:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 11:30:00" type="radio" id="11:30:00-12" value="11:30:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 11:30:00" type="radio" id="11:30:00-13" value="11:30:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 11:30:00" type="radio" id="11:30:00-14" value="11:30:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>13:00 - 14:00</th>
                    <td>
                      <input class="form-check-input class1 13:00:00" type="radio" id="13:00:00-1" value="13:00:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 13:00:00" type="radio" id="13:00:00-2" value="13:00:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 13:00:00" type="radio" id="13:00:00-3" value="13:00:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 13:00:00" type="radio" id="13:00:00-4" value="13:00:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 13:00:00" type="radio" id="13:00:00-5" value="13:00:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 13:00:00" type="radio" id="13:00:00-6" value="13:00:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 13:00:00" type="radio" id="13:00:00-7" value="13:00:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 13:00:00" type="radio" id="13:00:00-8" value="13:00:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 13:00:00" type="radio" id="13:00:00-9" value="13:00:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 13:00:00" type="radio" id="13:00:00-10" value="13:00:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 13:00:00" type="radio" id="13:00:00-11" value="13:00:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 13:00:00" type="radio" id="13:00:00-12" value="13:00:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 13:00:00" type="radio" id="13:00:00-13" value="13:00:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 13:00:00" type="radio" id="13:00:00-14" value="13:00:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>14:30 - 15:30</th>
                    <td>
                      <input class="form-check-input class1 14:30:00" type="radio" id="14:30:00-1" value="14:30:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 14:30:00" type="radio" id="14:30:00-2" value="14:30:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 14:30:00" type="radio" id="14:30:00-3" value="14:30:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 14:30:00" type="radio" id="14:30:00-4" value="14:30:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 14:30:00" type="radio" id="14:30:00-5" value="14:30:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 14:30:00" type="radio" id="14:30:00-6" value="14:30:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 14:30:00" type="radio" id="14:30:00-7" value="14:30:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 14:30:00" type="radio" id="14:30:00-8" value="14:30:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 14:30:00" type="radio" id="14:30:00-9" value="14:30:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 14:30:00" type="radio" id="14:30:00-10" value="14:30:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 14:30:00" type="radio" id="14:30:00-11" value="14:30:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 14:30:00" type="radio" id="14:30:00-12" value="14:30:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 14:30:00" type="radio" id="14:30:00-13" value="14:30:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 14:30:00" type="radio" id="14:30:00-14" value="14:30:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>16:00 - 17:00</th>
                    <td>
                      <input class="form-check-input class1 16:00:00" type="radio" id="16:00:00-1" value="16:00:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 16:00:00" type="radio" id="16:00:00-2" value="16:00:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 16:00:00" type="radio" id="16:00:00-3" value="16:00:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 16:00:00" type="radio" id="16:00:00-4" value="16:00:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 16:00:00" type="radio" id="16:00:00-5" value="16:00:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 16:00:00" type="radio" id="16:00:00-6" value="16:00:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 16:00:00" type="radio" id="16:00:00-7" value="16:00:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 16:00:00" type="radio" id="16:00:00-8" value="16:00:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 16:00:00" type="radio" id="16:00:00-9" value="16:00:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 16:00:00" type="radio" id="16:00:00-10" value="16:00:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 16:00:00" type="radio" id="16:00:00-11" value="16:00:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 16:00:00" type="radio" id="16:00:00-12" value="16:00:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 16:00:00" type="radio" id="16:00:00-13" value="16:00:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 16:00:00" type="radio" id="16:00:00-14" value="16:00:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>17:30 - 18:30</th>
                    <td>
                      <input class="form-check-input class1 17:30:00" type="radio" id="17:30:00-1" value="17:30:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 17:30:00" type="radio" id="17:30:00-2" value="17:30:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 17:30:00" type="radio" id="17:30:00-3" value="17:30:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 17:30:00" type="radio" id="17:30:00-4" value="17:30:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 17:30:00" type="radio" id="17:30:00-5" value="17:30:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 17:30:00" type="radio" id="17:30:00-6" value="17:30:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 17:30:00" type="radio" id="17:30:00-7" value="17:30:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 17:30:00" type="radio" id="17:30:00-8" value="17:30:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 17:30:00" type="radio" id="17:30:00-9" value="17:30:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 17:30:00" type="radio" id="17:30:00-10" value="17:30:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 17:30:00" type="radio" id="17:30:00-11" value="17:30:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 17:30:00" type="radio" id="17:30:00-12" value="17:30:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 17:30:00" type="radio" id="17:30:00-13" value="17:30:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 17:30:00" type="radio" id="17:30:00-14" value="17:30:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>19:00 - 20:00</th>
                    <td>
                      <input class="form-check-input class1 19:00:00" type="radio" id="19:00:00-1" value="19:00:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 19:00:00" type="radio" id="19:00:00-2" value="19:00:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 19:00:00" type="radio" id="19:00:00-3" value="19:00:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 19:00:00" type="radio" id="19:00:00-4" value="19:00:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 19:00:00" type="radio" id="19:00:00-5" value="19:00:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 19:00:00" type="radio" id="19:00:00-6" value="19:00:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 19:00:00" type="radio" id="19:00:00-7" value="19:00:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 19:00:00" type="radio" id="19:00:00-8" value="19:00:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 19:00:00" type="radio" id="19:00:00-9" value="19:00:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 19:00:00" type="radio" id="19:00:00-10" value="19:00:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 19:00:00" type="radio" id="19:00:00-11" value="19:00:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 19:00:00" type="radio" id="19:00:00-12" value="19:00:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 19:00:00" type="radio" id="19:00:00-13" value="19:00:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 19:00:00" type="radio" id="19:00:00-14" value="19:00:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                  <tr>
                    <th>20:00 - 21:00</th>
                    <td>
                      <input class="form-check-input class1 20:00:00" type="radio" id="20:00:00-1" value="20:00:00 - 1" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class2 20:00:00" type="radio" id="20:00:00-2" value="20:00:00 - 2" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class3 20:00:00" type="radio" id="20:00:00-3" value="20:00:00 - 3" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class4 20:00:00" type="radio" id="20:00:00-4" value="20:00:00 - 4" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class5 20:00:00" type="radio" id="20:00:00-5" value="20:00:00 - 5" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class6 20:00:00" type="radio" id="20:00:00-6" value="20:00:00 - 6" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class7 20:00:00" type="radio" id="20:00:00-7" value="20:00:00 - 7" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class8 20:00:00" type="radio" id="20:00:00-8" value="20:00:00 - 8" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class9 20:00:00" type="radio" id="20:00:00-9" value="20:00:00 - 9" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class10 20:00:00" type="radio" id="20:00:00-10" value="20:00:00 - 10" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class11 20:00:00" type="radio" id="20:00:00-11" value="20:00:00 - 11" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class12 20:00:00" type="radio" id="20:00:00-12" value="20:00:00 - 12" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class13 20:00:00" type="radio" id="20:00:00-13" value="20:00:00 - 13" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                    <td>
                      <input class="form-check-input class14 20:00:00" type="radio" id="20:00:00-14" value="20:00:00 - 14" v-model="selectedMeet" @change="selectDateAndRoom($event)"/>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex flex-column">
                  <span class="mt-2" style="font-weight: 800">Você selecionou:</span>
                <div class="d-flex flex-row" v-if="this.selectedMeet">
                  <span class="mx-1" style="color: blue"> Sala {{this.selectedMeet.substring(10,13)}} -</span>
                  <span class="mx-1" style="color: red"> {{this.showDateSelected.day}}/{{this.showDateSelected.month}}/{{this.showDateSelected.year}} às</span>
                  <span style="color: red"> {{this.selectedMeet.substring(0,5)}} até {{convertHorario(this.selectedMeet.substring(0,5))}}</span>
                </div>
                <div v-else>
                  <span>Nenhum Horario</span>
                </div>
              </div>
              <div class="button-confirm d-flex justify-content-around mt-5">
                <button class="btn btn-conff" @click="marcarHorario">Confirmar Reservas</button>
              </div>
            </div>
          </div>

          <div id="tab-home-office" class="tab-pane fade" aria-labelledby="tab-homeoffice" role="tabpanel">
            <div class="testttt">
              <div class="d-flex flex-row ms-5" style="margin-top: 5%">
                <span> Horários: </span>
                <select class="form-select-md" aria-label="Mês" ref="horarioSel" v-model="selectedHourHomeOffice" style="margin-left: 40%">
                  <option value="">Selecione</option>
                  <option value="08:30:00 - 09:30" id="08:30:00h" class="selectHours" hour-selected="08:30 - 09:30">08:30 - 09:30</option>
                  <option value="10:00:00 - 11:00" id="10:00:00h" class="selectHours" hour-selected="10:00 - 11:00">10:00 - 11:00</option>
                  <option value="11:30:00 - 12:30" id="11:30:00h" class="selectHours" hour-selected="11:30 - 12:30">11:30 - 12:30</option>
                  <option value="13:00:00 - 14:00" id="13:00:00h" class="selectHours" hour-selected="13:00 - 14:30">13:00 - 14:00</option>
                  <option value="14:30:00 - 15:30" id="14:30:00h" class="selectHours" hour-selected="14:30 - 15:30">14:30 - 15:30</option>
                  <option value="16:00:00 - 17:00" id="16:00:00h" class="selectHours" hour-selected="16:00 - 17:00">16:00 - 17:00</option>
                  <option value="17:30:00 - 18:30" id="17:30:00h" class="selectHours" hour-selected="17:30 - 18:30">17:30 - 18:30</option>
                  <option value="19:00:00 - 20:00" id="19:00:00h" class="selectHours" hour-selected="19:00 - 20:00">19:00 - 20:00</option>
                  <option value="20:00:00 - 21:00" id="20:00:00h" class="selectHours" hour-selected="20:00 - 21:00">20:00 - 21:00</option>
                </select>
              </div>
              <div class="d-flex flex-column ms-5">
                  <span class="mt-5" style="font-weight: 800">Você selecionou:</span>
                <div class="d-flex flex-row" v-if="this.selectedHourHomeOffice">
                  <span class="mx-1" style="color: red"> Dia {{this.showDateSelected.day}}/{{this.showDateSelected.month}}/{{this.showDateSelected.year}} às</span>
                  <span style="color: red"> {{this.selectedHourHomeOffice.substring(0,5)}} até {{convertHorario(this.selectedHourHomeOffice.substring(0,5))}}</span>
                </div>
                <div v-else>
                  <span>Nenhum Horario</span>
                </div>
              </div>
              <div class="button-confirm my-5 d-flex ms-5">
                
                <button class="btn btn-conff" @click="marcarHorario">Confirmar Reservas</button>
              </div> 
            </div>
          </div>

          <div id="tab-pos-graduacao" class="tab-pane fade" aria-labelledby="tab-posgraducao" role="tabpanel">
            <div class="testttt">
              <div class="d-flex flex-row ms-5" style="margin-top: 5%">
                <span> Horários: </span>
                <select class="form-select-md" aria-label="Mês" ref="horarioSelPos" v-model="SelectedHourPos" style="margin-left: 40%">
                  <option value="">Selecione</option>
                  <option value="19:00:00 - 21:30" id="19:00:00p" class="selectHours">19:00 - 21:30</option>
                </select>
              </div>
              <div class="d-flex flex-column ms-5">
                  <span class="mt-5" style="font-weight: 800">Você selecionou:</span>
                <div class="d-flex flex-row" v-if="this.SelectedHourPos">
                  <span class="mx-1" style="color: red"> Dia {{this.showDateSelected.day}}/{{this.showDateSelected.month}}/{{this.showDateSelected.year}} às</span>
                  <span style="color: red"> {{this.SelectedHourPos.substring(0,5)}} até {{this.SelectedHourPos.substring(11,16)}}</span>
                </div>
                <div v-else>
                  <span>Nenhum Horario</span>
                </div>
              </div>
              <div class="button-confirm my-5 d-flex ms-5">
                
                <button class="btn btn-conff" @click="marcarHorario">Confirmar Reservas</button>
              </div>  
            </div>
          </div>

        </div>
        
      </div>
    </div>
  </div>
  
  <div class="table__component d-flex flex-column px-5 mt-5">
    <div>
      <div class="d-flex flex-row mb-2"><span class="title-meet ms-3 mt-1 fs-4">Meus Agendamentos: </span>
        <select class="ms-2 form-select-md" id="mesAgendado" aria-label="Mês" ref="mes" selected="this.$refs.mes.value">
          <option v-for="(monthName, index) in months" :value="index+1" :key="index">{{monthName}}</option>
        </select>
        <select class="ms-2 form-select-md" id="anoAgendado" aria-label="Ano" ref="ano">
          <option v-for="ano in anoAtual" :key="ano.id">{{ano}}</option>
        </select>
        <button class="btn btn-primary ms-2 button__list" @click="retornaDados($refs.mes.value, $refs.ano.value)">Listar reuniões</button>
      </div>
    </div>
    <table class="mb-5">
      <thead class="table-titles">
        <tr>
          <th class="th-first">Data</th>
          <th>Sala</th>
          <th>Horário</th>
          <th>Assunto</th>
          <th>Professor</th>
          <th class="th-last">Ações</th>
        </tr>
      </thead>
      <tbody v-for="(data, index) in datesAgendar" :key="index">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <tr v-for="hour in data.hours" v-if="hour.occupied == true" :key="hour.id">
          <td v-if="mesDates < 10">{{ data.day }}/0{{mesDates}}/{{anoDates}}</td>
          <td v-else>{{ data.day }}/{{mesDates}}/{{anoDates}}</td>
          <td>{{ hour.topic.split(":")[0] }}</td>
          <td>{{ hour.inicial }} - {{ hour.final }}</td>
          <td>{{ hour.topic.split(":")[1] }}</td>
          <td>{{ hour.host_name }}</td>
          <td>
            <button v-if="data.day < 10" v-show="liberarAula(data.day, mesDates, anoDates, hour.inicial, hour.final)" style="color: gray" :id="hour.inicial + '-' + '0' + data.day + '/' + '0' + mesDates + '/' + anoDates + 'p'" class="btn bi bi-person-badge-fill button-teacher abrirAulaProfessor" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Professor" @click='clickVerAula(hour.join_url)' disabled></button>
            <button v-else v-show="liberarAula(data.day, mesDates, anoDates, hour.inicial, hour.final)" style="color: gray" :id="hour.inicial + '-' + data.day + '/' +  '0' + mesDates + '/' + anoDates + 'p'" class="btn bi bi-person-badge-fill button-teacher abrirAulaProfessor" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Professor" @click='clickVerAula(hour.join_url)' disabled></button>
            <button v-show="liberarGravacao(hour.play_url, hour.meetingId)" :id="hour.meetingId+'-button-watch'" class="btn bi bi-share button-watch" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Compartilhar Aula" @click='openShareMeetingModal(hour.meetingId, hour.topic.split(":")[1])'></button>
            <button class="btn bi bi-trash-fill" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Cancelar Aula" @click='openCancelMeetingModal(hour.meetingId, `${data.day}/${mesDates}/${anoDates}`)'></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <loading v-if="this.loading"></loading>
  <disclaimer-modal ref="disclaimerModal"></disclaimer-modal>
  <cancel-meeting-modal
  :meetingId="this.selectMeetingId"
  :data="this.selectData"
  @cancel="$emit('meeting-deleted', selectMeetingId)"
  ref="cancelMeetingModal"
  >
  </cancel-meeting-modal>
  <share-meeting-modal
  :meetingId="this.selectMeetingId"
  @share="$emit('shared-meeting', selectMeetingId)"
  ref="shareMeetingModal"
  >
  </share-meeting-modal>
</div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import Loading from '../plugins/Loading.vue';
import DisclaimerModal from '../components/modals/DisclaimerModal.vue';
import CancelMeetingModal from '../components/modals/CancelMeetingModal.vue';
import ShareMeetingModal from '../components/modals/ShareMeetingModal.vue';
import alert from '../plugins/alert';
import moment from 'moment'

export default {
  name: 'AgendarHorario',
  components: {
    FullCalendar,
    Loading,
    DisclaimerModal,
    CancelMeetingModal,
    ShareMeetingModal,
  },
  data() {
    return {
      horarioAtual: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      roleUser: '',
      userNotPermission: '',
      selectMeetingId: '',
      selectData:'',
      toastSuccess: false,
      toastError: false,
      loading: false,
      calendarOptions: {
        buttonText: {
        today: "Hoje",
        month: "Mês",
        week: "Semana",
        day: "Dia"
        },
        // hiddenDays: [ 0 ],
        selectable: true,
        axisFormat: "H:mm",
        ignoreTimezone: false,
        locale: "pt-br",
        editable: true,
        plugins: [dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin],
        displayEventTime: this.displayEventTime,
        slotLabelFormat: "HH:mm",
        timeFormat: "H:mm",
        dateFormat: "dd-mm-YYYY",
        initialView: "dayGridMonth",
        navLinks: false,
        weekends: true,
        firstDay: 1,
        headerToolbar: {
          left: "prev next today",
          center: "",
          right: "title",
        },
        dateClick: this.handleDateClick,
        select: this.handleSelect,
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      },
      dates: {
        day: Number,
        hours: [
          {
            final: String,
            inicial: String,
            occupied: Boolean,
            topic: String,
          },
        ],
        room: Number,
      },
      datesAgendar: {
        day: Number,
        hours: [
          {
            final: String,
            inicial: String,
            occupied: Boolean,
            topic: String,
          },
        ],
        room: Number,
      },
      datesCalendario: {
        day: Number,
        hours: [
          {
            final: String,
            inicial: String,
            occupied: Boolean,
            topic: String,
          },
        ],
        room: Number,
      },
      datesBlockDay: '',
      datesBlockHour: '',
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      mesDates: '',
      anoDates: '',
      mensagemSucesso: '',
      mes: '',
      ano: '',
      anoAtual: this.montaAno(),
      selectedMeet: null,
      horaLocal: '',
      status: '',
      idUser: '',
      idCourse: '',
      reuniao: String,
      dateSelected: null,
      showDateSelected: {
        year: '',
        month: '',
        day: '',
      },
      confirmarReuniao: '',
      nameCurso: Number,
      selectedHourHomeOffice: '',
      SelectedHourPos: '',
    };
  },
  beforeCreate() {
    var url = decodeURIComponent(window.location.href);
    if(url.includes('uuid:')) {
      let course_id = url.split('=');
      let user_id = course_id[1].indexOf('&');
      user_id = course_id[1].substring(0, user_id);

      let cc_id = course_id[2].indexOf('#');
      cc_id = course_id[2];

      localStorage.setItem('user_id', user_id);
      localStorage.setItem('course_id', cc_id);
    }

    let course_idS = localStorage.getItem('course_id');
    let user_idS = localStorage.getItem('user_id');
    // this.$loading(true);
    axios.post(`${process.env.VUE_APP_ROOT_API}/blackboard/users`, {
        course_id: course_idS,
        user_id: user_idS,
      }).then((resposta) => {
        localStorage.setItem('id', resposta.data.user.id);
        this.roleUser = resposta.data.roles ?  '' : resposta.data.roles[0].role
        if(resposta.data.roles && resposta.data.roles.length > 0 && resposta.data.roles[0].role) {
          this.userNotPermission = true;
          localStorage.setItem('userNotPermission', this.userNotPermission);
        } else {
          this.userNotPermission = false
          localStorage.setItem('userNotPermission', this.userNotPermission);
          document.getElementById('pnAdm').style.display = 'none'
        }



      }).catch((error) => {

        this.userNotPermission = false
        localStorage.setItem('userNotPermission', this.userNotPermission);
        const userPermission = localStorage.getItem('userNotPermission')

        if(userPermission) {
          document.getElementById('pnAdm').style.display = 'none'
        }

        this.$loading(false);
      });

      
    this.$loading(true);
      axios.get(`${process.env.VUE_APP_ROOT_API}/blackboard/courses/details/${course_idS}`, {
      }).then((resposta) => {
        this.nameCurso = resposta.data.data.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf("GRADUACAO AO VIVO")

        if(this.nameCurso == 0) {
          document.getElementById('tab-pos-graduacao').classList.add("show");
          document.getElementById('tab-pos-graduacao').classList.add("active");
          document.getElementById('buttonTabPos').classList.add("active");
          document.getElementById('liTabPos').classList.add("active");
        } else {
          document.getElementById('tab-home-office').classList.add("show");
          document.getElementById('tab-home-office').classList.add("active");
          document.getElementById('liTabCabine').classList.add("active");
          document.getElementById('buttonTabHome').classList.add("active");
        }
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.$loading(false);
      })

    let id = localStorage.getItem('id')
    let hoje = new Date();
    let mesBefore = hoje.getUTCMonth()+1;
    let anoBefore = hoje.getFullYear();
    axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/dates`, {
      month: mesBefore,
      year: anoBefore,
      course_id: course_idS,
    }).then((resposta) => {
      this.loading = true;
      let datesPresential = resposta.data.data.presential;
      let datesNotPresential = resposta.data.data.notPresential
      this.dates =  datesPresential.concat(datesNotPresential) ;
      this.datesCalendario =  datesPresential.concat(datesNotPresential) ;
      this.datesBlockDay = resposta.data.datesNotAllowed.allDay
      this.datesBlockHour = resposta.data.datesNotAllowed.dates
      this.mesDates = mesBefore.toString();
      this.anoDates = anoBefore.toString();
      document.getElementById('mesAgendado').value = mesBefore
      let diaCa = new Date();
      let dia = diaCa.getUTCDate();
      let mes = diaCa.getMonth()+1;
      let ano = diaCa.getFullYear();
      this.marcaLegenda(dia, mes, ano);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.loading = false;
    });

    axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/dates`, {
      month: mesBefore,
      year: anoBefore,
      course_id: course_idS,
      user_id: id
      //AQUI
    }).then((resposta) => {
      this.loading = true;
      let datesPresentialA = resposta.data.data.presential;
      let datesNotPresentialA = resposta.data.data.notPresential
      this.datesAgendar =  datesPresentialA.concat(datesNotPresentialA) ;
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.loading = false;
    });
  },
  mounted() {
    var getToday = new Date();
    const setToday = getToday.getDate();
    const setMonth = getToday.getUTCMonth()+1;
    const setYear = getToday.getUTCFullYear();
    this.showDateSelected.day = setToday;
    this.showDateSelected.month = setMonth;
    this.showDateSelected.year = setYear;
  },
  methods: {
    convertHorario(dd) {
      return `${Number(dd.split(':')[0])+1}:${dd.split(':')[1]}`
    },
    openCancelMeetingModal(id, data) {
      this.selectMeetingIdAndDataToSendModal(id, data);
      this.$refs.cancelMeetingModal.$refs.modal.open();
    },
    openShareMeetingModal(id, nomeCurso) {
      this.selectMeetingIdToSendModal(id);
      this.$refs.shareMeetingModal.$refs.modal.open();
      document.querySelector('.share-record-text').textContent = `Publicar a gravação de ${nomeCurso} `;
    },
    selectMeetingIdToSendModal(meetingId) {
      this.selectMeetingId = meetingId;
    },
    selectMeetingIdAndDataToSendModal(meetingId, data) {
      this.selectMeetingId = meetingId;
      this.selectData = data;
    },
    handleDateClick(arg) {
      Array.from(document.querySelectorAll('[data-date]')).forEach(el => {
            el.children[0].removeAttribute("style")
      })
      const getYear = arg.dateStr.substring(0,4);
      this.showDateSelected.year = getYear;
      const getMonth = arg.dateStr.substring(5,7);
      this.showDateSelected.month = getMonth;
      const getDay = arg.dateStr.substring(8,10);

      Array.from(document.querySelectorAll('[data-date]')).forEach(el => {
          if(el.getAttribute('data-date') == arg.dateStr) {
            el.children[0].style.backgroundColor = 'rgb(209, 243, 253)';
          }
      })
      
      this.showDateSelected.day = getDay;
      this.dateSelected = arg.dateStr;
      this.marcaLegenda(this.showDateSelected.day, this.showDateSelected.month, this.showDateSelected.year)
    },
    selectDateAndRoom(event) {
      this.selectedMeet = event.target.value;
      
    },
    retornaDados(mesRetorna, anoRetorna) {
      this.mesDates = this.$refs.mes.value;
      this.anoDates = this.$refs.ano.value;

      let course_idS = localStorage.getItem('course_id');
      let id = localStorage.getItem('id');
      this.$loading(true);
      axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/dates`, {
        month: mesRetorna,
        year: anoRetorna,
        course_id: course_idS,
        user_id: id
      }).then((resposta) => {
        this.loading = true;
        let datesPresentialA = resposta.data.data.presential;
        let datesNotPresentialA = resposta.data.data.notPresential;
        this.datesAgendar =  datesPresentialA.concat(datesNotPresentialA);
        this.dates = datesPresentialA.concat(datesNotPresentialA);
        this.datesCalendario = datesPresentialA.concat(datesNotPresentialA)
        this.marcaLegenda(this.showDateSelected.day, this.showDateSelected.month, this.showDateSelected.year)
      }).catch((error) => {
      console.error(error);
      }).finally(() => {
        this.$loading(false);
      });
    },
    marcarHorario() {
      let tab = document.querySelector('.tab-content .active')
      let tabSelect = tab.id == 'tab-presencial' ? true : false
      let valueHomeOrPos = '';
      let duracao = '60';

      if(tab.id == 'tab-home-office') {
        valueHomeOrPos = this.$refs.horarioSel.value.substring(0, 8)
        duracao = '60'
      } 

      if(tab.id == 'tab-pos-graduacao') {
        valueHomeOrPos = this.$refs.horarioSelPos.value.substring(0, 8)
        duracao = '150'
      }

      let horario = tabSelect ?  this.selectedMeet.substring(0, 8) : valueHomeOrPos;
      let room = tabSelect ? this.selectedMeet.substring(11, 18) : '';
      let dia = this.dateSelected;
      let mesRetorna = dia.split('-')[1]
      let anoRetorna = dia.split('-')[0]
      const diaMarcaLegenda = dia.split('-')
  
      if(mesRetorna < 10) {
        mesRetorna = mesRetorna.replace(/0/g, "")
      }
      let course_idS = localStorage.getItem('course_id');
      let id = localStorage.getItem('id');

      this.$refs.disclaimerModal.$refs.modal.open();
      document.getElementById('buttonColor').addEventListener('click', function(event) {
        if(document.getElementsByClassName('ms-2 form-check-input')[0].checked) {
          axios.post(`${process.env.VUE_APP_ROOT_API}/createmeeting/scheduled`, {
            startHour: horario,
            startDay: dia,
            isPresential: tabSelect,
            duration: duracao,
            room: room,
            userId:  id,
            courseId: course_idS,
          }).then((resposta) => {
            if(resposta.data.status) {
              alert.fireAlert('Aula marcada com sucesso!', {
                classes: 'alert-success bi bi-check-circle',
                styles:
                  'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            });
            document.getElementById('mesAgendado').value = mesRetorna
            document.getElementById('anoAgendado').value = anoRetorna
            document.getElementsByClassName('button__list')[0].click()
            }
          
          }).catch((error) => {
            if(error.response.data.specific_code) {
              if(error.response.data.specific_code == 9) {
              alert.fireAlert(`Não é possível realizar a marcação de aula em agendamento consecutivo.`, {
                classes: 'alert-danger bi bi-exclamation-circle',
                styles:
                  'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
                tag: 'httpAlert',
              });
            }

            if(error.response.data.specific_code != 9) {
              alert.fireAlert(`Não é possível marcar aula`, {
                classes: 'alert-danger bi bi-exclamation-circle',
                styles:
                  'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
                tag: 'httpAlert',
              });
            }
            }
            
          }).finally(() => {
          });
        } else {
          document.getElementsByClassName('erro-lgpd')[0].style.display = "block"
          return false;
        }
      }, {once : true})
    },
    montaAno() {
      let ano = new Date();
      let anoA = ano.getFullYear();
      let countAno = []

      for(let i = 0; i < 4; i++) {
        countAno[i] = anoA
        anoA++
      }
      return countAno;
    },


    // liberarAula(dia, mes, ano, horaInicial, horaFinal) {
    //   // Obtém a hora atual no formato 'HH:mm'
    //   const horaAtual = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //   const horaAtualM = moment(horaAtual, 'HH:mm');
      
    //   // Converte as horas de início e fim para moment.js
    //   const horaInicialM = moment(horaInicial, 'HH:mm');
    //   const horaFinalM = moment(horaFinal, 'HH:mm');
      
    //   // Define 15 minutos como uma duração
    //   const quinzeMinutos = moment.duration('00:15:00');
      
    //   // Obtém a data atual usando moment.js
    //   const myDate = moment().format('DD/MM/YYYY');

    //   // Corrige o formato do dia e do mês, caso sejam menores que 10
    //   dia = dia < 10 ? '0' + dia : dia;
    //   mes = mes < 10 ? '0' + mes : mes;

    //   // Inicia o setTimeout para verificar a condição
    //   setTimeout(() => {
    //     if (dia + '/' + mes + '/' + ano === myDate) {
    //       // Cria uma cópia da hora inicial para não modificar o valor original
    //       const horaInicialMModificada = moment(horaInicialM);

    //       // Verifica se a hora atual é maior ou igual a 15 minutos antes da hora inicial e antes da hora final
    //       if (horaAtualM.isSameOrAfter(horaInicialMModificada.subtract(quinzeMinutos)) && horaAtualM.isBefore(horaFinalM)) {
            
    //         const element = document.getElementById(horaInicial + '-' + dia + '/0' + mes + '/' + ano + 'p');
            
    //         // Verifica se o elemento existe
    //         if (element) {
    //           element.disabled = false;
    //           element.style.color = 'blue';
    //         }
    //       }
        
    //       // Verifica se a hora atual passou do horário final
    //       if (horaFinalM.isAfter(horaAtualM)) {
    //         const element = document.getElementById(horaInicial + '-' + dia + '/0' + mes + '/' + ano + 'p');
            
    //         // Verifica se o elemento existe
    //         if (element) {
    //           element.disabled = true;
    //           element.style.color = 'gray';
    //         }
    //       }
    //     }
    //   }, 200); // Executa a verificação após 200ms
    //   return true;
    // },

    liberarAula(dia, mes, ano, horaInicial, horaFinal) {
      const horaAtual = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const horaAtualM = moment(horaAtual, 'HH:mm');
      
      const horaInicialM = moment(horaInicial, 'HH:mm');
      const horaFinalM = moment(horaFinal, 'HH:mm');
      
      const quinzeMinutos = moment.duration('00:15:00');
      
      const myDate = moment().format('DD/MM/YYYY');

      dia = dia < 10 ? '0' + dia : dia;
      mes = mes < 10 ? '0' + mes : mes;

      setTimeout(() => {
        if (dia + '/' + mes + '/' + ano === myDate) {
          const horaInicialMModificada = moment(horaInicialM);

          if (horaAtualM.isSameOrAfter(horaInicialMModificada.subtract(quinzeMinutos)) && horaAtualM.isBefore(horaFinalM)) {
            const element = document.getElementById(`${horaInicial}-${dia}/${mes}/${ano}p`);

            //exemplo botão id - Liberação de aula
            //16:00-03/02/2025p

            if (element) {
              element.disabled = false;
              element.style.color = 'blue';
            }
          }
        
          //Bloqueia após o horário final da aula = Após o horário final da aula o botão ficaria bloqueado

          // if (horaFinalM.isAfter(horaAtualM)) {
          //   const element = document.getElementById(`${horaInicial}-${dia}/${mes}/${ano}p`);
          //   console.log(element);
          //   if (element) {
          //     element.disabled = true;
          //     element.style.color = 'gray';
          //   }
          // }
        }
      }, 200);
      return true;
    },


    liberarGravacao(existeGravacao, meetingId) {
      setTimeout(() => {
        if(!existeGravacao) {
          const Alo = `${meetingId}-button-watch`;
          if(document.getElementById(Alo)) {
            document.getElementById(Alo).disabled = true
            document.getElementById(Alo).style.color = 'grey'
          }
        }
      }, 200)
      
      return true
    },
    clickVerAula(url) {
      window.open(url);
    },
    marcaLegenda(dia, mesA, ano) {
      Array.from(document.querySelectorAll('.form-check-input')).forEach (el => {
        el.disabled = false;
        el.classList.remove('block-hours')
        el.classList.remove('select-room')
      });

      Array.from(document.querySelectorAll('.selectHours')).forEach (el => {
        el.disabled = false;
        el.classList.remove('block-hours')
      });

      let dataCalendario = new Date();
      let mesCalendario = dataCalendario.getMonth()+1;
      let tab = document.querySelector('.tab-content .active')
      let tabSelect = tab.id == 'tab-presencial' ? true : false
      let course_idS = localStorage.getItem('course_id');
      let id = localStorage.getItem('id');

      if(mesCalendario != mesA) {
        this.$loading(true);
        axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/dates`, {
        month: parseInt(mesA),
        year: ano,
        course_id: course_idS
        }).then((resposta) => {
          this.loading = true;
          let datesPresential = resposta.data.data.presential;
          let datesNotPresential = resposta.data.data.notPresential
          let horarios =  datesPresential.concat(datesNotPresential);
          let roomsBlocks = resposta.data.datesNotAllowed.allDay;
          let horariosBlocks = resposta.data.datesNotAllowed.dates;
          let horaBlock = '';
          let salaDayBlock = ''
          let dayBlock = ''
          roomsBlocks.forEach(index => {
            salaDayBlock = index.date.split(" ")
            dayBlock = salaDayBlock[0].split("-")
            if(this.showDateSelected.day == dayBlock[2] && this.showDateSelected.month == dayBlock[1]) {
              document.getElementById(index.room_id).classList.add('block-day')
              Array.from(document.querySelectorAll(`.class${index.room_id}`)).forEach(el => {
                el.classList.add('block-hours')
                el.disabled = true
              });            
            }
          });

            horariosBlocks.forEach(index => {
              horaBlock = index.date.split(" ");
              let mesBlock = horaBlock[0].split("-")

              if(this.showDateSelected.day == mesBlock[2] && this.showDateSelected.month == mesBlock[1]) {
                if(tabSelect) {
                  if(index.room_id) {
                    document.getElementById(horaBlock[1] + '-' + index.room_id).disabled = true
                    document.getElementById(horaBlock[1] + '-' + index.room_id).classList.add('block-hours');
                  }
                } else {
                  if(!index.room_id) {
                    if(tab.id == 'tab-pos-graduacao') {
                      if(index.type_meeting == 'live') {
                        document.getElementById(horaBlock[1]).classList.add('block-hours')
                        document.getElementById(horaBlock[1]).disabled = true;
                      }

                    }

                    if(tab.id == 'tab-home-office') {
                      if(index.type_meeting == 'home') {
                        document.getElementById(horaBlock[1]).classList.add('block-hours')
                        document.getElementById(horaBlock[1]).disabled = true;
                      }
                    }
                  }
                }
              } 
            })

            horarios.forEach(index => {
              index.hours.forEach(horario => {
                if(horario.occupied == true && index.day == dia){
                  
                  Array.from(document.getElementsByClassName(horario.inicial+':00')).forEach(el => {
                    el.disabled = true
                });
                
                document.getElementById(horario.inicial+':00h').disabled = true;
                document.getElementById(horario.inicial+':00h').classList.add('block-hours');
                let numeroSala = horario.topic.split(':')[0].split(' ')[1]

                if(numeroSala) {
                  for(let i = 1; i <= 14; i++){
                    document.getElementById(horario.inicial+':00'+'-'+i).disabled = true;
                  }
                }

                if(tab.id == 'tab-pos-graduacao') {
                  document.getElementById(horario.inicial+':00p').disabled = true;
                  document.getElementById(horario.inicial+':00p').classList.add('block-hours');
                }
                }
              })
            })
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          this.$loading(false);
        });
      } else {
        let horariosBlocks = this.datesBlockHour; // Blocks de horarios
        let roomsBlocks = this.datesBlockDay; //Blocks AllDay
        let horaBlock = '';
        let horarios = this.datesCalendario;
        let salaDayBlock = ''
        let dayBlock = ''
        if(tab.id == 'tab-presencial') {
          roomsBlocks.forEach(index => {
            salaDayBlock = index.date.split(" ")
            dayBlock = salaDayBlock[0].split("-")
            if(this.showDateSelected.day == dayBlock[2]) {
              document.getElementById(index.room_id).classList.add('block-day')
              Array.from(document.querySelectorAll(`.class${index.room_id}`)).forEach(el => {
                el.classList.add('block-hours')
                el.disabled = true
              });            
            }
          });
        }

          horariosBlocks.forEach(index => {
            horaBlock = index.date.split(" ");
            let mesBlock = horaBlock[0].split("-")
            if(this.showDateSelected.day == mesBlock[2]) {
              if(tabSelect) { 
                if(index.room_id) {
                  document.getElementById(horaBlock[1] + '-' + index.room_id).disabled = true
                  document.getElementById(horaBlock[1] + '-' + index.room_id).classList.add('block-hours');
                }
              } else {
                if(!index.room) {
                    if(tab.id == 'tab-pos-graduacao') {
                      if(index.type_meeting == 'live') {
                        document.getElementById(horaBlock[1]+'p').classList.add('block-hours')
                        document.getElementById(horaBlock[1]+'p').disabled = true;
                      }

                    }

                    if(tab.id == 'tab-home-office') {
                      if(index.type_meeting == 'home') {
                        document.getElementById(horaBlock[1]+'h').classList.add('block-hours')
                        document.getElementById(horaBlock[1]+'h').disabled = true;
                      }
                    }
                  }
              }
            }
          })

          horarios.forEach(index => {
            index.hours.forEach(horario => {
              if(horario.occupied == true && index.day == dia) {

                Array.from(document.getElementsByClassName(horario.inicial+':00')).forEach(el => {
                    el.disabled = true
                });
                
                document.getElementById(horario.inicial+':00h').disabled = true;
                document.getElementById(horario.inicial+':00h').classList.add('block-hours');

                let numeroSala = horario.topic.split(':')[0].split(' ')[1]

                if(numeroSala) {
                  for(let i = 1; i <= 14; i++){
                    document.getElementById(horario.inicial+':00'+'-'+i).disabled = true;
                  }
                }
                
                if(tab.id == 'tab-pos-graduacao') {
                  document.getElementById(horario.inicial+':00p').disabled = true;
                  document.getElementById(horario.inicial+':00p').classList.add('block-hours');
                }
              }
            })
          })
      }

    },
    converteMes(mesA) {
      let meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

      var teste = document.getElementsByClassName('fc-toolbar-title');
      

      return meses.indexOf(mesA);

    }
  },
};
</script>

<style scoped>

.fc-scrollgrid-sync-inner {
  text-transform: capitalize;
}
.fc-today-button {
  font-weight: bold !important;
  color: #1874a4 !important;
  background-color: #add8e6 !important;
}

.bi {
  color: #1874a4;
}

.button__list {
  font-weight: bold;
  color: #FFF;
  background-color: var(--azul-tres);
}
.info-data {
  font-size: 16px;
  
}
.info-mark-meet {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
}
.bd-all {
  border: 1px solid #000;
}
.info-title-deactive {
  font-weight: bold;
  color: #808080;
  background-color: #d3d3d3;
}
.info-title-reserved {
  font-weight: bold;
  color: #ffa500;
  background-color: #ffe4c4;
}
.info-title-me-reserved {
  font-weight: bold;
  color: #f00;
  background-color: #d49f9f;
}
.info-title-me {
  font-weight: bold;
  color: rgb(73 177 138);
  background-color: #00ff04;
}
.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}
table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
table td {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.btn-conff {
  color: #fff;
  background-color: var(--azul-tres);
  font-weight: bold;
}

.button-delete {
  color: red;
}

.button-watch {
  color: var(--azul-tres);
}

.button-teacher {
  color: #000;
}
#hour {
  font-size: 12px;
}

#tab-home-office {
  width: 642.641px;
  height: auto;
}

#tab-pos-graduacao {
  width: 642.641px;
  height: auto;
}

.form-check-input {
  border: 8px solid rgba(0,0,0,.25);
}

input[type="radio"]:disabled {
  background: #ffa500;
}

.form-check-input:checked {
  background: #f00;
}

input[type="radio"] {
  background: var(--azul-tres);
}

.block-hours {
  background-color: #cbccd0;
  border-color: #cbccd0;
}
.select-room {
  background-color: #00ff40;
  border-color: #00ff26;
}
.testttt {
  border: solid 2px black;
  margin-top: 10px;
  padding-bottom: 40px;
  width: 80%
}
/* .reservado {

}

.desativado {
  
} */
</style>
